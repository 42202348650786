import React from 'react'
// import {Link} from 'gatsby'
import Layout from "../layouts/index";

const AboutPage = props => (
  <Layout location={props.location}>
    <div>AboutPage</div>
  </Layout>
  // <div
  //   css={`
  //     background-image: url(${props.data && props.data.page.headingImage ? props.data.page.headingImage.fixed.src : null});
  //     background-size: cover;
  //     padding: 20px;
  //     height: 100vh;
  //     width: 100vw;
  //     display: flex;
  //     flex-direction: column;
  //     justify-items: center;
  //   `}
  // >
  //   { props.data ? <div css={`color: #fff; font-size: 20px; font-family: 'Oswald', sans-serif; `} dangerouslySetInnerHTML={{ __html: props.data.page.headingText.childMarkdownRemark.html}}></div> : null }
  // </div>
)

export default AboutPage
