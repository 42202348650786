import React from 'react'
import Helmet from 'react-helmet'

import SiteContainer from '../components/common/SiteContainer'
import { ThemeProvider } from 'emotion-theming'
import theme from '../theme'
import Footer from '../components/Footer'
import Header from '../components/Header'
import PageBody from '../components/common/PageBody'

import bodyBg from '../assets/images/body-bg.jpg'
import '../assets/css/index.css'

const DefaultLayout = ({ children, location }) => (
  <ThemeProvider theme={theme}>
    <SiteContainer background={location.pathname !== '/' ? null : bodyBg}>
      {location.pathname !== '/' ? <Header /> : null}
      <Helmet
        title="The Hitchhiker's Guide to Javascript"
        meta={[
          {
            name: 'description',
            content: 'A roaring adventure through the universe of Javascript',
          },
        ]}
      />
      {location.pathname !== '/' ? (
        <PageBody>{children}</PageBody>
      ) : (
        children
      )}
      <Footer transparent={location.pathname !== '/' ? false : true} />
    </SiteContainer>
  </ThemeProvider>
)

export default DefaultLayout
